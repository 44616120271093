import * as React from 'react';
import {SvgProps, Svg, Path} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const SuccessContourIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        {...props}
      >
        <Path
          d="M15.613 3.506a12.5 12.5 0 018.13 2.682.75.75 0 00.93-1.178 14 14 0 105.013 8.043.75.75 0 00-1.466.316 12.5 12.5 0 11-12.607-9.863z"
          fill={color}
        />
        <Path
          d="M31.03 6.03a.75.75 0 00-1.06-1.06L15 19.94l-4.97-4.97a.75.75 0 10-1.06 1.06l5.5 5.5a.75.75 0 001.06 0l15.5-15.5z"
          fill={color}
        />
      </Svg>
    );
  }
);
