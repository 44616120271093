import * as React from 'react';
import {BoxProps, useTheme, hexToRGBA} from '@youtoken/ui.primitives';
import {IButtonProps} from '../types';
import {TouchableOpacity} from 'react-native';

type ButtonTouchableComponentProps = IButtonProps &
  BoxProps &
  React.ComponentProps<typeof TouchableOpacity> & {children?: any};

const useButtonSizeProps = (size: IButtonProps['size'], children: any) => {
  return React.useMemo(() => {
    return {
      large: {
        px: children ? 24 : 15,
        height: 48,
        borderRadius: 6,
      },
      medium: {
        px: children ? 24 : 11,
        height: 40,
        borderRadius: 6,
      },
      small: {
        px: children ? 16 : 8,
        height: 32,
        borderRadius: 5,
      },
      tiny: {
        px: children ? 8 : 4,
        height: 24,
        borderRadius: 4,
      },
    }[size];
  }, [size, children]);
};

const useColorProps = (
  type: IButtonProps['type'],
  color: IButtonProps['color'],
  disabled: IButtonProps['disabled']
): ButtonTouchableComponentProps => {
  const {colors} = useTheme();

  return React.useMemo(() => {
    if (disabled) {
      if (color === 'apple-pay') {
        return (
          {
            primary: {
              bg: '$ui-04',
            },
            secondary: {
              bg: '$ui-04',
            },
            ghost: {
              bg: '$ui-background',
            },
            inverted: {
              bg: '$text-04',
            },
          } as {[key in IButtonProps['type']]: ButtonTouchableComponentProps}
        )[type];
      }
      return (
        {
          primary: {
            bg: '$ui-01',
          },
          secondary: {
            bg: '$ui-01',
          },
          ghost: {
            bg: '$ui-background',
          },
          inverted: {
            bg: '$text-04',
          },
        } as {[key in IButtonProps['type']]: ButtonTouchableComponentProps}
      )[type];
    }

    return (
      {
        interactive: {
          primary: {
            bg: '$interactive-01',
            underlayColor: colors['$ui-background'],
            rippleColor: hexToRGBA(colors['$ui-background'], 0.15),
          },
          secondary: {
            bg: '$interactive-02',
            underlayColor: colors['$interactive-04'],
            rippleColor: hexToRGBA(colors['$interactive-01'], 0.1),
          },
          ghost: {
            bg: '$ui-background',
            underlayColor: colors['$interactive-02'],
            rippleColor: hexToRGBA(colors['$interactive-01'], 0.05),
          },
          inverted: {
            bg: '$text-04',
            underlayColor: colors['$interactive-01'],
            rippleColor: hexToRGBA(colors['$interactive-01'], 0.1),
          },
        },
        'external-auth': {
          primary: {
            bg: '$interactive-03',
            underlayColor: colors['$ui-background'],
            rippleColor: hexToRGBA(colors['$ui-background'], 0.15),
          },
          secondary: {
            bg: '$interactive-03',
            underlayColor: colors['$interactive-04'],
            rippleColor: hexToRGBA(colors['$interactive-01'], 0.1),
          },
          ghost: {
            bg: '$ui-background',
            underlayColor: colors['$interactive-02'],
            rippleColor: hexToRGBA(colors['$interactive-01'], 0.05),
          },
          inverted: {
            bg: '$text-04',
            underlayColor: colors['$interactive-01'],
            rippleColor: hexToRGBA(colors['$interactive-01'], 0.1),
          },
        },
        success: {
          primary: {
            bg: '$success-01',
            underlayColor: colors['$ui-background'],
            rippleColor: hexToRGBA(colors['$ui-background'], 0.2),
          },
          secondary: {
            bg: '$success-02',
            underlayColor: colors['$success-01'],
            rippleColor: hexToRGBA(colors['$success-01'], 0.1),
          },
          ghost: {
            bg: '$ui-background',
            underlayColor: colors['$success-01'],
            rippleColor: hexToRGBA(colors['$success-01'], 0.05),
          },
          inverted: {
            bg: '$text-04',
            underlayColor: colors['$success-01'],
            rippleColor: hexToRGBA(colors['$success-01'], 0.1),
          },
        },
        danger: {
          primary: {
            bg: '$danger-01',
            underlayColor: colors['$ui-background'],
            rippleColor: hexToRGBA(colors['$ui-background'], 0.2),
          },
          secondary: {
            bg: '$danger-02',
            underlayColor: colors['$danger-01'],
            rippleColor: hexToRGBA(colors['$danger-01'], 0.1),
          },
          ghost: {
            bg: '$ui-background',
            underlayColor: colors['$danger-01'],
            rippleColor: hexToRGBA(colors['$danger-01'], 0.05),
          },
          inverted: {
            bg: '$text-04',
            underlayColor: colors['$danger-01'],
            rippleColor: hexToRGBA(colors['$danger-01'], 0.1),
          },
        },
        attention: {
          primary: {
            bg: '$attention-01',
            underlayColor: colors['$ui-background'],
            rippleColor: hexToRGBA(colors['$ui-background'], 0.2),
          },
          secondary: {
            bg: '$attention-02',
            underlayColor: colors['$attention-01'],
            rippleColor: hexToRGBA(colors['$attention-01'], 0.1),
          },
          ghost: {
            bg: '$ui-background',
            underlayColor: colors['$attention-01'],
            rippleColor: hexToRGBA(colors['$attention-01'], 0.05),
          },
          inverted: {
            bg: '$text-04',
            underlayColor: colors['$attention-01'],
            rippleColor: hexToRGBA(colors['$attention-01'], 0.1),
          },
        },
        'apple-pay': {
          primary: {
            bg: '$fill-04',
            underlayColor: colors['$ui-background'],
            rippleColor: hexToRGBA(colors['$ui-background'], 0.2),
          },
          secondary: {
            bg: '$fill-04',
            underlayColor: colors['$attention-01'],
            rippleColor: hexToRGBA(colors['$attention-01'], 0.1),
          },
          ghost: {
            bg: '$ui-background',
            underlayColor: colors['$attention-01'],
            rippleColor: hexToRGBA(colors['$attention-01'], 0.05),
          },
          inverted: {
            bg: '$text-06',
            underlayColor: colors['$attention-01'],
            rippleColor: hexToRGBA(colors['$attention-01'], 0.1),
          },
        },
      } as {
        [key in IButtonProps['color']]: {
          [key in IButtonProps['type']]: ButtonTouchableComponentProps;
        };
      }
    )[color][type];
  }, [color, type, disabled]);
};

export const useButtonStyleProps = ({
  size = 'medium',
  type = 'primary',
  color = 'interactive',
  disabled = false,
  children,
  ...props
}: ButtonTouchableComponentProps): ButtonTouchableComponentProps => {
  const colorProps = useColorProps(type, color, disabled);
  const sizeProps = useButtonSizeProps(size, children);

  return {
    alignItems: 'center',
    justifyContent: 'center',
    ...sizeProps,
    ...colorProps,
    ...props,
  };
};
