import * as React from 'react';
import {SvgProps} from 'react-native-svg';
import {ActionsIcon as actions} from './Actions';
import {AntiPhishingIcon as anti_phishing} from './AntiPhishing';
import {AppearanceIcon as appearance} from './Appearance';
import {AppleIcon as apple} from './Apple';
import {BackspaceIcon as backspace} from './Backspace';
import {BankWireIcon as bank_wire} from './BankWire';
import {BellIcon as bell} from './Bell';
import {BlockexplorerIcon as blockexplorer} from './Blockexplorer';
import {BoostIcon as boost} from './Boost';
import {CalendarIcon as calendar} from './Calendar';
import {CaptureIcon as capture} from './Capture';
import {CardIcon as card} from './Card';
import {ChartBarIcon as chart_bar} from './ChartBar';
import {ChartCandlesIcon as chart_candles} from './ChartCandles';
import {ChartLineIcon as chart_line} from './ChartLine';
import {CheckIcon as check} from './Check';
import {ChevronDownIcon as chevron_down} from './ChevronDown';
import {ChevronLeftIcon as chevron_left} from './ChevronLeft';
import {ChevronRightIcon as chevron_right} from './ChevronRight';
import {ChevronUpIcon as chevron_up} from './ChevronUp';
import {CloseIcon as close} from './Close';
import {CloseNowIcon as close_now} from './CloseNow';
import {CloudIcon as cloud} from './Cloud';
import {CollapseIcon as collapse} from './Collapse';
import {ConvertIcon as convert} from './Convert';
import {CopyIcon as copy} from './Copy';
import {CryptoDepositIcon as crypto_deposit} from './CryptoDeposit';
import {CryptoWithdrawIcon as crypto_withdraw} from './CryptoWithdraw';
import {DemoFundsIcon as demo_funds} from './DemoFunds';
import {DevicesIcon as devices} from './Devices';
import {DiscountIcon as discount} from './Discount';
import {DocAddressIcon as doc_address} from './DocAddress';
import {DocScanIcon as doc_scan} from './DocScan';
import {DocumentIcon as document} from './Document';
import {DownIcon as down} from './Down';
import {DownloadIcon as download} from './Download';
import {DragIcon as drag} from './Drag';
import {DualIcon as dual} from './Dual';
import {EditIcon as edit} from './Edit';
import {EmailIcon as email} from './Email';
import {ExchangeIcon as exchange} from './Exchange';
import {ExpandIcon as expand} from './Expand';
import {ExtendIcon as extend} from './Extend';
import {ExternalLinkIcon as external_link} from './ExternalLink';
import {FaceIdIcon as face_id} from './FaceId';
import {FailContourIcon as fail_contour} from './FailContour';
import {FiatDepositIcon as fiat_deposit} from './FiatDeposit';
import {FiatWithdrawIcon as fiat_withdraw} from './FiatWithdraw';
import {FilterIcon as filter} from './Filter';
import {FingerprintIcon as fingerprint} from './Fingerprint';
import {FirstTradeIcon as first_trade} from './FirstTrade';
import {FtpIcon as ftp} from './Ftp';
import {GetLoanIcon as get_loan} from './GetLoan';
import {GiftIcon as gift} from './Gift';
import {GoogleIcon as google} from './Google';
import {HeartBrokenIcon as heart_broken} from './HeartBroken';
import {HexagonSparkIcon as hexagon_spark} from './HexagonSpark';
import {HexagonIcon as hexagon} from './Hexagon';
import {HideIcon as hide} from './Hide';
import {HistoryIcon as history} from './History';
import {HodlIcon as hodl} from './Hodl';
import {IdCardIcon as id_card} from './IdCard';
import {IdDriverIcon as id_driver} from './IdDriver';
import {IdScanIcon as id_scan} from './IdScan';
import {IdVerificationIcon as id_verification} from './IdVerification';
import {IncreaseIcon as increase} from './Increase';
import {IncreasePositionIcon as increase_position} from './IncreasePosition';
import {InterestIcon as interest} from './Interest';
import {LanguageIcon as language} from './Language';
import {LinkIcon as link} from './Link';
import {LocationIcon as location} from './Location';
import {LockIcon as lock} from './Lock';
import {MessageIcon as message} from './Message';
import {MinerBlockCostIcon as miner_block_cost} from './MinerBlockCost';
import {MiningIcon as mining} from './Mining';
import {MoneyIcon as money} from './Money';
import {MoreIcon as more} from './More';
import {OsAndroidIcon as os_android} from './OsAndroid';
import {OsLinuxIcon as os_linux} from './OsLinux';
import {OsMacIcon as os_mac} from './OsMac';
import {OsOtherIcon as os_other} from './OsOther';
import {OsWindowsIcon as os_windows} from './OsWindows';
import {PassportIcon as passport} from './Passport';
import {PendingIcon as pending} from './Pending';
import {PhoneIcon as phone} from './Phone';
import {PinIcon as pin} from './Pin';
import {PlayIcon as play} from './Play';
import {PlusIcon as plus} from './Plus';
import {ProfileIcon as profile} from './Profile';
import {QrIcon as qr} from './Qr';
import {QuestionIcon as question} from './Question';
import {QuestionOutlinedIcon as question_outlined} from './QuestionOutlined';
import {RefreshIcon as refresh} from './Refresh';
import {RemoveIcon as remove} from './Remove';
import {ReopenIcon as reopen} from './Reopen';
import {SearchIcon as search} from './Search';
import {SecurityChangesIcon as security_changes} from './SecurityChanges';
import {SendIcon as send} from './Send';
import {SettingsIcon as settings} from './Settings';
import {ShareIcon as share} from './Share';
import {ShowIcon as show} from './Show';
import {SignIcon as sign} from './Sign';
import {SignOutIcon as sign_out} from './SignOut';
import {SnowflakeIcon as snowflake} from './Snowflake';
import {SnowflakeCrossedIcon as snowflake_crossed} from './SnowflakeCrossed';
import {SortingIcon as sorting} from './Sorting';
import {SparkIcon as spark} from './Spark';
import {SpinnerIcon as spinner} from './Spinner';
import {StarIcon as star} from './Star';
import {StarFilledIcon as star_filled} from './StarFilled';
import {SuccessIcon as success} from './Success';
import {SuccessContourIcon as success_contour} from './SuccessContour';
import {TopGainersIcon as top_gainers} from './TopGainers';
import {TopLoosersIcon as top_loosers} from './TopLoosers';
import {TradingViewIcon as trading_view} from './TradingView';
import {TradingIcon as trading} from './Trading';
import {TurboIcon as turbo} from './Turbo';
import {TwofaIcon as twofa} from './Twofa';
import {UmaIcon as uma} from './Uma';
import {UpIcon as up} from './Up';
import {VerificationIcon as verification} from './Verification';
import {VerifiedIcon as verified} from './Verified';
import {VideoIcon as video} from './Video';
import {VolumeIcon as volume} from './Volume';
import {WalletsIcon as wallets} from './Wallets';
import {WarningIcon as warning} from './Warning';
import {Web3Icon as web_3} from './Web3';

export type IconName =
  | 'actions'
  | 'anti_phishing'
  | 'appearance'
  | 'apple'
  | 'backspace'
  | 'bank_wire'
  | 'bell'
  | 'blockexplorer'
  | 'boost'
  | 'calendar'
  | 'capture'
  | 'card'
  | 'chart_bar'
  | 'chart_candles'
  | 'chart_line'
  | 'check'
  | 'chevron_down'
  | 'chevron_left'
  | 'chevron_right'
  | 'chevron_up'
  | 'close'
  | 'close_now'
  | 'cloud'
  | 'collapse'
  | 'convert'
  | 'copy'
  | 'crypto_deposit'
  | 'crypto_withdraw'
  | 'demo_funds'
  | 'devices'
  | 'discount'
  | 'doc_address'
  | 'doc_scan'
  | 'document'
  | 'down'
  | 'download'
  | 'drag'
  | 'dual'
  | 'edit'
  | 'email'
  | 'exchange'
  | 'expand'
  | 'extend'
  | 'external_link'
  | 'face_id'
  | 'fail_contour'
  | 'fiat_deposit'
  | 'fiat_withdraw'
  | 'filter'
  | 'fingerprint'
  | 'first_trade'
  | 'ftp'
  | 'get_loan'
  | 'gift'
  | 'google'
  | 'heart_broken'
  | 'hexagon_spark'
  | 'hexagon'
  | 'hide'
  | 'history'
  | 'hodl'
  | 'id_card'
  | 'id_driver'
  | 'id_scan'
  | 'id_verification'
  | 'increase'
  | 'increase_position'
  | 'interest'
  | 'language'
  | 'link'
  | 'location'
  | 'lock'
  | 'message'
  | 'miner_block_cost'
  | 'mining'
  | 'money'
  | 'more'
  | 'os_android'
  | 'os_linux'
  | 'os_mac'
  | 'os_other'
  | 'os_windows'
  | 'passport'
  | 'pending'
  | 'phone'
  | 'pin'
  | 'play'
  | 'plus'
  | 'profile'
  | 'qr'
  | 'question'
  | 'question_outlined'
  | 'refresh'
  | 'remove'
  | 'reopen'
  | 'search'
  | 'security_changes'
  | 'send'
  | 'settings'
  | 'share'
  | 'show'
  | 'sign'
  | 'sign_out'
  | 'snowflake'
  | 'snowflake_crossed'
  | 'sorting'
  | 'spark'
  | 'spinner'
  | 'star'
  | 'star_filled'
  | 'success'
  | 'success_contour'
  | 'top_gainers'
  | 'top_loosers'
  | 'trading_view'
  | 'trading'
  | 'turbo'
  | 'twofa'
  | 'uma'
  | 'up'
  | 'verification'
  | 'verified'
  | 'video'
  | 'volume'
  | 'wallets'
  | 'warning'
  | 'web_3';

export const iconSet: {
  [key in IconName]: React.FC<SvgProps & {size?: number}>;
} = {
  actions,
  anti_phishing,
  appearance,
  apple,
  backspace,
  bank_wire,
  bell,
  blockexplorer,
  boost,
  calendar,
  capture,
  card,
  chart_bar,
  chart_candles,
  chart_line,
  check,
  chevron_down,
  chevron_left,
  chevron_right,
  chevron_up,
  close,
  close_now,
  cloud,
  collapse,
  convert,
  copy,
  crypto_deposit,
  crypto_withdraw,
  demo_funds,
  devices,
  discount,
  doc_address,
  doc_scan,
  document,
  down,
  download,
  drag,
  dual,
  edit,
  email,
  exchange,
  expand,
  extend,
  external_link,
  face_id,
  fail_contour,
  fiat_deposit,
  fiat_withdraw,
  filter,
  fingerprint,
  first_trade,
  ftp,
  get_loan,
  gift,
  google,
  heart_broken,
  hexagon_spark,
  hexagon,
  hide,
  history,
  hodl,
  id_card,
  id_driver,
  id_scan,
  id_verification,
  increase,
  increase_position,
  interest,
  language,
  link,
  location,
  lock,
  message,
  miner_block_cost,
  mining,
  money,
  more,
  os_android,
  os_linux,
  os_mac,
  os_other,
  os_windows,
  passport,
  pending,
  phone,
  pin,
  play,
  plus,
  profile,
  qr,
  question,
  question_outlined,
  refresh,
  remove,
  reopen,
  search,
  security_changes,
  send,
  settings,
  share,
  show,
  sign,
  sign_out,
  snowflake,
  snowflake_crossed,
  sorting,
  spark,
  spinner,
  star,
  star_filled,
  success,
  success_contour,
  top_gainers,
  top_loosers,
  trading_view,
  trading,
  turbo,
  twofa,
  uma,
  up,
  verification,
  verified,
  video,
  volume,
  wallets,
  warning,
  web_3,
};
