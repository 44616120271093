import * as React from 'react';
import {Text, TextProps} from '@youtoken/ui.primitives';
import {IButtonProps} from '../types';

const getTextSizeVariant = (size: IButtonProps['size']): TextProps => {
  return (
    {
      large: {
        variant: '$body-01-medium-accent',
        weight: 'medium',
      },
      medium: {
        variant: '$body-01-medium-accent',
        weight: 'medium',
      },
      small: {
        variant: '$body-02-medium-accent',
        weight: 'medium',
      },
      tiny: {
        variant: '$body-02-medium-accent',
        weight: 'medium',
      },
    } as {[key in IButtonProps['size']]: TextProps}
  )[size];
};

const getTextColorVariant = (
  type: IButtonProps['type'],
  color: IButtonProps['color'],
  disabled: IButtonProps['disabled']
): TextProps => {
  if (disabled) {
    if (color === 'apple-pay') {
      if (type === 'inverted' || type === 'ghost') {
        return {
          color: '$text-02',
        };
      }
      return {
        color: '$text-04',
      };
    }
    return {
      color: '$text-03',
    };
  }

  return (
    {
      interactive: {
        primary: {
          color: '$text-04',
        },
        secondary: {
          color: '$interactive-01',
        },
        ghost: {
          color: '$interactive-01',
        },
        inverted: {
          color: '$interactive-01',
        },
      },
      'external-auth': {
        primary: {
          color: '$text-01',
        },
        secondary: {
          color: '$interactive-01',
        },
        ghost: {
          color: '$interactive-01',
        },
        inverted: {
          color: '$interactive-01',
        },
      },
      danger: {
        primary: {
          color: '$text-04',
        },
        secondary: {
          color: '$danger-01',
        },
        ghost: {
          color: '$danger-01',
        },
        inverted: {
          color: '$danger-01',
        },
      },
      success: {
        primary: {
          color: '$text-04',
        },
        secondary: {
          color: '$success-01',
        },
        ghost: {
          color: '$success-01',
        },
        inverted: {
          color: '$success-01',
        },
      },
      attention: {
        primary: {
          color: '$text-04',
        },
        secondary: {
          color: '$attention-01',
        },
        ghost: {
          color: '$attention-01',
        },
        inverted: {
          color: '$attention-01',
        },
      },
      'apple-pay': {
        primary: {
          color: '$text-06',
        },
        secondary: {
          color: '$text-06',
        },
        ghost: {
          color: '$fill-04',
        },
        inverted: {
          color: '$fill-04',
        },
      },
    } as {
      [key in IButtonProps['color']]: {
        [key in IButtonProps['type']]: TextProps;
      };
    }
  )[color][type];
};

const useTextStyleProps = ({
  size = 'medium',
  type = 'primary',
  color = 'interactive',
  disabled = false,
}: IButtonProps): TextProps => {
  return {
    ...getTextSizeVariant(size),
    ...getTextColorVariant(type, color, disabled),
  };
};

export const TextComponent: React.FC<IButtonProps> = ({children, ...props}) => {
  const styleProps = useTextStyleProps(props);

  return (
    <Text
      accessibilityRole="text"
      accessible={false}
      numberOfLines={1}
      {...styleProps}
    >
      {children}
    </Text>
  );
};
