import * as React from 'react';
import {SvgProps, Svg, Path, Rect} from 'react-native-svg';

let uniqueId = 0;

const getUniqueId = () => uniqueId++;

export const FailContourIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    const _id = React.useMemo(() => getUniqueId(), []);

    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 81 80"
        fill="none"
        {...props}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.8 51.7a2.121 2.121 0 010-3l8.7-8.7-8.7-8.7a2.121 2.121 0 113-3l8.7 8.7 8.7-8.7a2.121 2.121 0 013 3L43.5 40l8.7 8.7a2.121 2.121 0 01-3 3L40.5 43l-8.7 8.7a2.121 2.121 0 01-3 0z"
          fill={color}
        />
        <Rect
          x={2}
          y={1.5}
          width={77}
          height={77}
          rx={38.5}
          stroke={color}
          strokeWidth={3}
        />
      </Svg>
    );
  }
);
